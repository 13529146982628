import { FC } from 'react';
import { Control, Controller, FieldError } from 'react-hook-form';
import ReactSelect from 'react-select';
import InputContainer from './InputContainer';

export type SelectOption = {
    label: string;
    value: number | string | boolean;
};

type Props = {
    name: string;
    label?: string;
    required?: boolean;
    className?: string;
    control: Control<any>;
    error?: FieldError;
    options: SelectOption[];
    placeholder?: string;
    hasEmptyOption?: boolean;
    multi?: boolean;
    emptyOptionLabel?: string;
    defaultValue?: any;
    value?: any;
    onChange?: any;
    isDisabled?: boolean;
    tooltip?: string;
    callback?: any;
};

const IndicatorSeparator = () => <></>;

type BaseSelectInputProps = Omit<Props, 'control'>;

export const BaseSelectInput: FC<BaseSelectInputProps> = ({
    name,
    label,
    required = false,
    className,
    error,
    placeholder = 'Selecione...',
    options,
    hasEmptyOption,
    multi,
    emptyOptionLabel,
    tooltip,
    callback,
    ...rest
}) => {
    let availableOptions = hasEmptyOption
        ? [{ value: '', label: emptyOptionLabel || '-' }, ...options]
        : options;

    return (
        <InputContainer
            name={name}
            label={label}
            className={className}
            error={error}
            tooltip={tooltip}
        >
            <ReactSelect
                classNamePrefix="Select"
                options={availableOptions}
                components={{ IndicatorSeparator }}
                placeholder={placeholder}
                isMulti={multi}
                closeMenuOnSelect={!multi}
                {...rest}
                onChange={(...args) => {
                    rest.onChange(...args);
                    callback?.();
                }}

                // {...style}
            />
        </InputContainer>
    );
};

const SelectInput: FC<Props> = ({ name, control, ...props }) => {
    return (
        <Controller
            name={name}
            control={control}
            render={({ field }) => <BaseSelectInput {...props} {...field} />}
        />
    );
};

export default SelectInput;
