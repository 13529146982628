import { FC, ReactNode, useEffect, useState } from 'react';
import { Control, useFieldArray } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import {
    AccentButton,
    DeleteConfirmationModal,
    ErrorMessage,
    FormLine,
    Input,
    NumberInput,
    SelectInput,
} from '../../../common/components';
import { DatePickerRangeControlledInput } from '../../../common/components/DatePickerRangeInput';
import { AdvertisignCampaignApprovalPillComponent } from '../../../common/components/StatusPillComponent';
import { formatCurrency } from '../../../common/utils/format.utils';

interface Props {
    control: Control<any>;
    // handleSubmit: UseFormHandleSubmit<any>;
    onSubmit: (data: any) => void;
    onPublish: (data: any) => void;
    errors: any;
    error?: string;
    loading?: boolean;
    isEdit?: boolean;
    setContentTime?: any;
    advertisingContentOptions: SelectOption[];
    advertisingUFCityOptions: SelectOption[];
    advertisingSegmentOptions: SelectOption[];
    campaignInformation?: AdvertisingCampaignInformation;
    canPublish?: boolean;
    campaignApprovals?: Partial<CampaignApproval>[];
    triggerCalculate: () => void;
    disabledContentDurationList: boolean[];
    dataHasChanged?: boolean;
    onDelete?: any;
}

const AdvertisingCampaignForm: FC<Props> = ({
    control,
    onSubmit,
    onPublish,
    errors,
    error,
    loading,
    isEdit,
    setContentTime,
    advertisingContentOptions,
    advertisingUFCityOptions,
    advertisingSegmentOptions,
    campaignInformation,
    canPublish,
    campaignApprovals,
    triggerCalculate,
    disabledContentDurationList,
    dataHasChanged,
    onDelete,
}) => {
    const navigate = useNavigate();
    const goBack = () => navigate(-1);

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'advertising_campaign_content',
    });

    const addContent = () => {
        append({ content_id: null, display_duration: null });
    };

    const removeContent = (ind: number) => {
        remove(ind);
    };

    useEffect(() => {
        console.log(JSON.stringify({ errors }, null, 4));
    }, [errors]);

    const renderCampaignInformation = () => {
        if (!campaignInformation) {
            return <span>Preencha os dados acima</span>;
        }
        console.log({ campaignInformation });

        return (
            <>
                <span>Locais: {campaignInformation.location_count}</span>
                <span>Máquinas: {campaignInformation.device_count}</span>
                <span>Dias: {campaignInformation.campaign_total_days}</span>
                <span>
                    Total de exposições: {campaignInformation.campaign_total_exposition_count}
                </span>
                <span>
                    Tempo total de veiculação: {campaignInformation.campaign_total_hours} horas
                </span>
                <span>
                    Valor por minuto:{' '}
                    {formatCurrency(campaignInformation.campaign_exposition_minute_cost, '')}
                </span>
            </>
        );
    };

    const [deleteConfirmation, setDeleteConfirmation] = useState(false);

    const openDeletConfirmation = (e: any) => {
        e?.preventDefault();
        setDeleteConfirmation(true);
    };

    const closeDeleteConfirmation = () => setDeleteConfirmation(false);

    return (
        <>
            <form className="flex flex-col space-y-6 divide-y" onSubmit={onSubmit}>
                <ErrorMessage message={error} />
                {/* dados da campanha */}
                <Section>
                    <DescriptionColumn
                        title="Dados da campanha"
                        description="Informações da campanha, período de veiculação e
valor."
                    />
                    <FormColumn>
                        <Input
                            control={control}
                            name="name"
                            label="Nome da campanha"
                            error={errors?.name}
                        />
                        <DatePickerRangeControlledInput
                            control={control}
                            name="campaign_period"
                            label="Período de veiculação"
                            tooltip="Período que a campanha será apresentada"
                            error={errors?.campaign_period?.start || errors?.campaign_period?.end}
                        />

                        <NumberInput
                            control={control}
                            name="campaign_value"
                            label="Valor da campanha (R$)"
                            type="number"
                            error={errors?.campaign_value}
                            currency
                        />
                    </FormColumn>
                </Section>

                {/* conteudos da campanha */}
                <Section>
                    <DescriptionColumn
                        title="Conteúdos da campanha"
                        description={[
                            'Conteúdos e duração da apresentação',
                            'O tempo de duração aqui informado irá sobrescrever o tempo informado durante o cadastro do conteúdo',
                            'Se o tempo de duração informado for maior que o tempo de duração real de um vídeo, será iniciada uma nova reprodução do conteúdo do vídeo',
                        ]}
                    />
                    <FormColumn>
                        {fields.map((item, ind) => (
                            <FormLine key={item.id}>
                                <SelectInput
                                    control={control}
                                    name={`advertising_campaign_content.${ind}.content_id`}
                                    label="Conteúdo"
                                    error={errors?.advertising_campaign_content?.[ind]?.content_id}
                                    options={advertisingContentOptions}
                                    callback={() => setContentTime(ind)}
                                />
                                <NumberInput
                                    control={control}
                                    name={`advertising_campaign_content.${ind}.display_duration`}
                                    label="Duração"
                                    type="number"
                                    className="sm:w-28"
                                    error={
                                        errors?.advertising_campaign_content?.[ind]
                                            ?.display_duration
                                    }
                                    format="##:##"
                                    tooltip="Hint: Duração em minutos e segundos (mm:ss)"
                                    callback={triggerCalculate}
                                    disabled={disabledContentDurationList[ind]}
                                />
                                <span
                                    onClick={() => removeContent(ind)}
                                    className="self-end mb-2 ml-5 text-red-500 cursor-pointer hover:text-red-800"
                                >
                                    Excluir
                                </span>
                            </FormLine>
                        ))}
                        <FormLine className="flex justify-end w-full">
                            <AccentButton className="w-44" onClick={addContent} type="button">
                                Adicionar conteúdo
                            </AccentButton>
                        </FormLine>
                    </FormColumn>
                </Section>

                {/* segmentacao de conteudo */}
                <Section>
                    <DescriptionColumn
                        title="Segmentação de conteúdo"
                        description={[
                            'Possibilidade de filtrar a UF, Cidade, Segmento e Local de apresentação do conteúdo',
                            'Os filtros são agregados. Ex: ao selecionar UF / Cidade serão apresentados segmentos e locais das cidades escolhidas',
                        ]}
                    />
                    <FormColumn>
                        <SelectInput
                            control={control}
                            name="segment_city_state"
                            label="UF / Cidade"
                            error={errors?.segment_city_state}
                            options={advertisingUFCityOptions}
                            tooltip="Estados e cidades que a campanha será veiculada"
                            multi
                        />
                        <SelectInput
                            control={control}
                            name="segment_segment_location"
                            label="Segmento / Local"
                            error={errors?.segment_segment_location}
                            options={advertisingSegmentOptions}
                            multi
                        />
                    </FormColumn>
                </Section>

                {/* exposicao */}
                <Section>
                    <DescriptionColumn
                        title="Exposição"
                        description="Número de vezes que o conteúdo será apresentado no período de 1 hora"
                    />
                    <FormColumn>
                        <Input
                            control={control}
                            name="max_display_by_hour"
                            label="Número máximo de exposições por hora"
                            error={errors?.max_display_by_hour}
                            type="number"
                        />
                    </FormColumn>
                </Section>

                {/* dados da campanha (gerado pela api) */}
                <Section>
                    <DescriptionColumn
                        title="Dados da campanha"
                        description="Informações da campanha"
                    />
                    <FormColumn>
                        <p className="mb-1 text-sm font-medium leading-6 text-gray-900 whitespace-nowrap">
                            Dados da campanha
                        </p>
                        <div className="flex flex-col gap-3 px-6 py-4 text-sm font-medium bg-gray-100 rounded-md">
                            {renderCampaignInformation()}
                        </div>
                    </FormColumn>
                </Section>

                {/* aprovações */}
                <Section>
                    <DescriptionColumn
                        title="Aprovações"
                        description="Locais onde é necessária aprovação da campanha pelo ponto"
                    />
                    <FormColumn>
                        <p className="mb-1 text-sm font-medium leading-6 text-gray-900 whitespace-nowrap">
                            Aprovações
                        </p>
                        <div className="flex flex-col px-6 text-sm font-medium bg-gray-100 rounded-md divide-y-2">
                            <ApprovalsDisplay campaignApprovals={campaignApprovals} />
                        </div>
                    </FormColumn>
                </Section>

                <div className="flex justify-between items-center pt-7 text-sm">
                    {isEdit && (
                        <AccentButton
                            type="button"
                            loading={loading}
                            className="px-4 py-2 w-max"
                            color="red"
                            onClick={openDeletConfirmation}
                        >
                            Excluir campanha
                        </AccentButton>
                    )}
                    <div className="flex justify-end items-center">
                        <Link to="#" className="px-7 py-2 font-bold" onClick={goBack}>
                            Cancelar
                        </Link>
                        {canPublish ? (
                            <>
                                <AccentButton
                                    type="submit"
                                    loading={loading}
                                    className="px-4 py-2 ml-3 w-max"
                                >
                                    Salvar como rascunho
                                </AccentButton>
                                <AccentButton
                                    type="button"
                                    color="green"
                                    loading={loading}
                                    className="px-4 py-2 ml-3 w-max"
                                    onClick={onPublish}
                                    disabled={dataHasChanged}
                                >
                                    Publicar
                                </AccentButton>
                            </>
                        ) : (
                            <AccentButton
                                type="submit"
                                loading={loading}
                                color="green"
                                className="px-4 py-2 ml-3 w-max"
                            >
                                Salvar
                            </AccentButton>
                        )}
                    </div>
                </div>
            </form>
            <DeleteConfirmationModal
                title="Excluir Campanha"
                description="Você tem certeza que deseja excluir essa campanha?"
                open={deleteConfirmation}
                closeModal={closeDeleteConfirmation}
                acceptButtonAction={onDelete}
                acceptButtonCompleteLabel="Sim, excluir campanha"
                loading={loading}
            />
        </>
    );
};

interface ApprovalsDisplayProps {
    campaignApprovals?: Partial<CampaignApproval>[];
}

const ApprovalsDisplay: FC<ApprovalsDisplayProps> = ({ campaignApprovals }) => {
    if (!campaignApprovals?.length) {
        return <div className="py-4">Não há aprovações para esta campanha</div>;
    }

    return (
        <>
            {campaignApprovals.map((campaignApproval) => (
                <div className="flex flex-col gap-1 py-4" key={campaignApproval?.point?.name}>
                    <span className="mb-1 font-bold">{campaignApproval?.point?.name}</span>
                    {campaignApproval.campaign_approval_locations?.map((approval_location) => (
                        <div className="flex justify-between">
                            <span>{approval_location?.location?.name}</span>
                            <AdvertisignCampaignApprovalPillComponent
                                value={approval_location.status as any}
                            />
                        </div>
                    ))}
                </div>
            ))}
        </>
    );
};

interface Children {
    children?: ReactNode;
}

const Section: FC<Children> = ({ children }) => {
    return (
        <div className="pt-7 pb-7 w-full md:grid md:grid-cols-3 md:gap-6 first:pt-0 last:pb-0">
            {children}
        </div>
    );
};

interface DescriptionColumnProps {
    title: string;
    description: string | string[];
}

const DescriptionColumn: FC<DescriptionColumnProps> = ({ title, description }) => {
    if (typeof description === 'string') {
        description = [description];
    }
    return (
        <div className="md:col-span-1">
            <h3 className="text-lg font-bold leading-6 text-gray-900">{title}</h3>
            <div>
                {description.map((line: string, ind: number) => (
                    <p className="mt-4 text-sm text-gray-500" key={ind}>
                        {line}
                    </p>
                ))}
            </div>
        </div>
    );
};

const FormColumn: FC<Children> = ({ children }) => {
    return <div className="mt-5 space-y-6 w-full md:col-span-2 md:mt-0">{children}</div>;
};

export default AdvertisingCampaignForm;
