import { FC, useEffect, useState } from 'react';
import {
    AccentButton,
    BaseInput,
    BaseSelectInput,
    BasicButton,
    DeleteConfirmationModal,
    Pagination,
    Table,
} from '../../../common/components';
import { useAdvertisingContents } from '../../../common/hooks';
import {
    ImagePreviewComponent,
    UpperCaseComponent,
} from '../../../common/components/Table/TableComponents';
import { MinutesParserComponent } from '../../partner/rents/PartnerRentList';
import { HeaderEndLink } from '../../../common/components/Table/Table';

const headers = [
    {
        label: 'Preview',
        path: 'file_url',
        Component: ImagePreviewComponent,
    },
    {
        label: 'Conteúdo',
        path: 'name',
        containerClassName: 'sm:font-medium sm:text-gray-900',
    },
    { label: 'Formato', path: 'file_ext', Component: UpperCaseComponent },
    { label: 'Duração', path: 'duration', Component: MinutesParserComponent },
];

const ARCHIVED_OPTIONS = [
    { label: 'Ativo', value: false },
    { label: 'Arquivado', value: true },
];

interface Props {
    orgId?: string;
}

const AdvertisingContent: FC<Props> = ({ orgId }) => {
    const { advertisingContents, fetchAdvertisingContents, deleteAdvertisingContent, loading } =
        useAdvertisingContents(orgId);

    const [advertisingContentToDelete, setAdvertisingContentToDelete] =
        useState<AdvertisingContent>();
    const closeAdvertisingContentDeleteModal = () => setAdvertisingContentToDelete(undefined);

    const [search, setSearch] = useState('');

    const [archived, setArchived] = useState(ARCHIVED_OPTIONS[0]);

    const submitSearch = () => {
        fetchAdvertisingContents({ search, archived: archived.value });
    };

    const changePage = (page: number) => {
        if (page !== advertisingContents?.meta.currentPage) {
            fetchAdvertisingContents({ page: page.toString(), search, archived: archived.value });
        }
    };

    const onClickDelete = (advertisingContent: AdvertisingContent) => {
        setAdvertisingContentToDelete(advertisingContent);
    };

    const onConfirmDelete = async () => {
        if (!advertisingContentToDelete) return;
        try {
            await deleteAdvertisingContent(advertisingContentToDelete.id);
        } catch (err) {
            // try again...
        }

        setAdvertisingContentToDelete(undefined);
        submitSearch();
    };

    const addAdvertisingContentTitle = 'Adicionar conteúdo';

    const activePath = orgId ? `/orgs/${orgId}` : '';
    const addAdvertisingContentHref = `${activePath}/advertising/contents/new`;
    const editAdvertisingContentHref = `${activePath}/advertising/contents/edit`;

    const [submitTrigger, setSubmitTrigger] = useState('');

    const clearFilters = () => {
        setArchived(ARCHIVED_OPTIONS[0]);
        setSearch('');
        // submitSearch(); <=== this doesnt work
        setSubmitTrigger(new Date().toISOString());
    };

    useEffect(() => {
        if (submitTrigger) {
            submitSearch();
        }
        // eslint-disable-next-line
    }, [submitTrigger]);

    return (
        <>
            <div className="flex flex-row flex-wrap gap-2 items-end">
                <BaseInput
                    name="Nome"
                    label="Nome"
                    placeholder="Buscar..."
                    value={search}
                    onChange={(e: any) => setSearch(e.target.value)}
                    className="!max-w-[220px]"
                    inputClassName="h-[38px]"
                />
                {/* <SearchBarInput
                    label="Nome"
                    search={search}
                    setSearch={setSearch}
                    submit={submitSearch}
                    className=""
                /> */}
                <BaseSelectInput
                    name="archived"
                    label="Status"
                    value={archived}
                    onChange={(option: any) => {
                        setArchived(option);
                    }}
                    className="!max-w-[220px]"
                    options={ARCHIVED_OPTIONS}
                />
                <div>
                    <BasicButton
                        // className="h-[36px] border-0 !shadow-none px-6"
                        className="h-[38px] !shadow-none px-6"
                        onClick={clearFilters}
                    >
                        Limpar
                    </BasicButton>
                </div>
                <div>
                    <AccentButton className="h-[38px] px-6" onClick={() => submitSearch()}>
                        Filtrar
                    </AccentButton>
                </div>
                <HeaderEndLink
                    to={addAdvertisingContentHref}
                    label={addAdvertisingContentTitle}
                    linkClassName="max-h-[38px] h-[38px]"
                />
            </div>
            <Table
                headers={headers}
                data={advertisingContents?.data || []}
                onClickDelete={onClickDelete}
                className="mt-4"
                // titleButtonLabel={addAdvertisingContentTitle}
                // titleButtonHref={addAdvertisingContentHref}
                editPath={editAdvertisingContentHref}
                // searchBarProps={{ search, setSearch, submit: submitSearch }}
                loading={loading}
            />
            <Pagination meta={advertisingContents?.meta} changePage={changePage} />
            <DeleteConfirmationModal
                title="Excluir conteúdo"
                description="Você tem certeza que deseja excluir essa conteúdo? 
                Essa operação não pode ser desfeita."
                acceptButtonLabel="conteúdo"
                open={!!advertisingContentToDelete}
                closeModal={closeAdvertisingContentDeleteModal}
                acceptButtonAction={onConfirmDelete}
            />
        </>
    );
};

export default AdvertisingContent;
