import { yupResolver } from '@hookform/resolvers/yup';
import { FC, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAdvertisingCampaigns } from '../../../common/hooks';
import { payloadFromSchema, schema } from './CreateAdvertisingCampaign';
import AdvertisingCampaignForm from './AdvertisingCampaignForm';
import { intSecondsToFormattedMinutes } from '../../../common/utils/date.utils';
import useAdvertisingCampaignForm from './useAdvertisingCampaignForm';
import { getAccessType } from '../../../common/store/slices/application';
import { useSelector } from 'react-redux';

// advertising_campaign_content: [{ content_id: null, display_duration: null }] as unknown as {
//     content_id: { value: string; label: string };
//     display_duration: string;
// }[],

const createFormData = (
    data: AdvertisingCampaign,
    segment_city_options: SelectOption[],
    segment_location_options: SelectOption[],
    content_options: SelectOption[],
) => {
    if (!data) return {};
    console.log('creating form data...');

    // SELECTED SEGMENT CITY OPTIONS
    const selected_segment_city = data.segment_city_state?.split(';');
    const selected_segment_city_options = selected_segment_city
        .map((segment_city) => {
            const segment_city_option = segment_city_options.find(
                (option) => option.value === segment_city,
            );
            return segment_city_option;
        })
        .filter((item) => !!item);

    // SELECTED SEGMENT LOCATION OPTIONS
    const selected_segment_location = data.segment_segment_location?.split(';');
    const selected_segment_location_options = selected_segment_location
        .map((segment_location) => {
            const segment_location_option = segment_location_options.find(
                (option) => option.value === segment_location,
            );
            return segment_location_option;
        })
        .filter((item) => !!item);

    // SELECTED CONTENT OPTIONS
    const selected_content_options = data.advertising_campaign_content?.map((content: any) => {
        const content_option = content_options.find(
            (option) => option.value === content.content.id,
        ) || { value: content.content.id, label: content.content.name };

        return {
            content_id: content_option,
            display_duration: intSecondsToFormattedMinutes(content.display_duration),
        };
    });

    const payload = {
        ...data,
        campaign_period: {
            startDate: new Date(data.campaign_start_date).toISOString().split('T')[0],
            endDate: new Date(data.campaign_end_date).toISOString().split('T')[0],
        },
        segment_city_state: selected_segment_city_options,
        segment_segment_location: selected_segment_location_options,
        advertising_campaign_content: selected_content_options,
    };

    return payload;
};

interface Props {
    orgId?: string;
}

const UpdateAdvertisingCampaign: FC<Props> = ({ orgId }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const advertisingCampaignData = location.state?.data;

    const [initialCampaignData, setInitialCampaignData] = useState(advertisingCampaignData);

    const {
        fetchAdvertisingCampaign,
        advertisingContentOptions,
        advertisingUFCityOptions,
        advertisingSegmentOptions,
        campaignInformation,
        resetCampaignInformation,
        calculateCampaignInformation,
        updateAdvertisingCampaign,
        deleteAdvertisingCampaign,
        loading,
        error,
    } = useAdvertisingCampaigns({
        fetchDataOptions: true,
        orgId,
        campaignId: initialCampaignData?.id,
    });

    useEffect(() => {
        console.log('initial data changed', initialCampaignData);
    }, [initialCampaignData]);

    const fetchUpdatedCampaignInformation = async () => {
        const updatedCampaignData = await fetchAdvertisingCampaign(advertisingCampaignData?.id);
        console.log({ updatedCampaignData });
        setInitialCampaignData(updatedCampaignData);
    };

    useEffect(() => {
        fetchUpdatedCampaignInformation();
        // eslint-disable-next-line
    }, [advertisingCampaignData]);

    const accessType = useSelector(getAccessType);
    const asPartner = accessType === 'PARTNER' || (accessType === 'ADMIN' && orgId !== 'ADMIN');

    const {
        control,
        handleSubmit,
        formState: { isDirty, errors },
        reset,
        watch,
        setValue,
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: createFormData(
            initialCampaignData,
            advertisingUFCityOptions,
            advertisingSegmentOptions,
            advertisingContentOptions,
        ) as any,
    });

    const alreadyUpdated = useRef(false);

    useEffect(() => {
        if (initialCampaignData && !alreadyUpdated.current) {
            reset(
                createFormData(
                    initialCampaignData,
                    advertisingUFCityOptions,
                    advertisingSegmentOptions,
                    advertisingContentOptions,
                ),
            );
        }

        // eslint-disable-next-line
    }, [
        initialCampaignData,
        advertisingUFCityOptions,
        advertisingSegmentOptions,
        advertisingContentOptions,
    ]);

    const onSubmit = async (data: any) => {
        const payload = payloadFromSchema(data);

        payload.campaign_value = payload.campaign_value.toFixed(2);

        try {
            await updateAdvertisingCampaign(initialCampaignData?.id, payload);
            const activePath = asPartner ? `/orgs/${orgId}` : '';
            navigate(`${activePath}/advertising/campaigns`);
        } catch (err) {
            console.log('failed submit...', err);
            // try again...
        }
    };

    const { setContentTime, triggerCalculate, disabledContentDurationList } =
        useAdvertisingCampaignForm({
            control,
            watch,
            setValue,
            schema,
            campaignInformation,
            calculateCampaignInformation,
            resetCampaignInformation,
        });

    const _onSubmit = (e?: any) => {
        e?.preventDefault();
        handleSubmit(onSubmit)();
    };

    const _onPublish = (e: any) => {
        e?.preventDefault();
        setValue('publish', true);
        _onSubmit();
    };

    const onDelete = async (e: any) => {
        // prevent form submit
        e?.preventDefault();

        // delete campaign
        await deleteAdvertisingCampaign(advertisingCampaignData.id);

        // go back to campaigns list/calendar
        const activePath = asPartner ? `/orgs/${orgId}` : '';
        navigate(`${activePath}/advertising/campaigns`);
    };

    return (
        <AdvertisingCampaignForm
            control={control}
            onSubmit={_onSubmit}
            onPublish={_onPublish}
            errors={errors} // client-side validation
            error={error} // server-side error
            loading={loading}
            isEdit={true}
            setContentTime={setContentTime}
            advertisingContentOptions={advertisingContentOptions}
            advertisingUFCityOptions={advertisingUFCityOptions}
            advertisingSegmentOptions={advertisingSegmentOptions}
            campaignInformation={campaignInformation}
            canPublish={!initialCampaignData?.published_at}
            campaignApprovals={initialCampaignData?.campaign_approvals}
            triggerCalculate={triggerCalculate}
            disabledContentDurationList={disabledContentDurationList}
            dataHasChanged={isDirty}
            onDelete={onDelete}
        />
    );
};

export default UpdateAdvertisingCampaign;
