import { FC } from 'react';
import { Control, Controller, FieldError } from 'react-hook-form';
import InputContainer from './InputContainer';
import Toggle from './Toggle';
import ErrorMessage from './ErrorMessage';

type Props = React.InputHTMLAttributes<HTMLInputElement> & {
    name: string;
    label?: any;
    className?: string;
    control: Control<any>;
    error?: FieldError;
    labelActive?: string;
    labelDisabled?: string;
    displayLabels?: boolean;
    disabled?: boolean;
    inputClassName?: string;
    onChangeCallback?: (val: boolean) => void;
    toggleProps?: any;
};

const Input: FC<Props> = ({
    name,
    label,
    className = '',
    labelActive = 'Ativado',
    labelDisabled = 'Desativado',
    displayLabels = true,
    control,
    error,
    disabled,
    inputClassName = '',
    onChangeCallback,
    toggleProps = {},
}) => {
    console.log('AY', error);
    return (
        <Controller
            name={name}
            control={control}
            render={({ field }) => (
                <div className="flex flex-col items-end">
                    <InputContainer
                        name={name}
                        className={`flex items-center fex-row ${className}`}
                        // error={error}
                        label=" "
                        innerClassName={`flex items-center ${inputClassName}`}
                    >
                        <Toggle
                            enabled={field?.value}
                            setEnabled={(value: boolean) => {
                                field.onChange(value);
                                onChangeCallback?.(value);
                            }}
                            disabled={disabled}
                            {...toggleProps}
                        />
                        {displayLabels && (
                            <label
                                htmlFor={name}
                                className="flex items-center ml-4 text-sm font-medium leading-6 text-gray-900 whitespace-nowrap"
                            >
                                {label?.[field?.value] ||
                                    (field?.value ? labelActive : labelDisabled)}
                            </label>
                        )}
                    </InputContainer>

                    <ErrorMessage message={error?.message} />
                </div>
            )}
        />
    );
};

export default Input;
