import { FC, ReactNode, useEffect, useState } from 'react';
import { Control, UseFormHandleSubmit } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import {
    AccentButton,
    DeleteConfirmationModal,
    ErrorMessage,
    FileInput,
    FormDescriptionColumn,
    Input,
    NumberInput,
} from '../../../common/components';
import InputContainer from '../../../common/components/InputContainer';
import ContentPreview from '../campaign-approval/ContentPreviewComponent';
import { intSecondsToFormattedMinutes } from '../../../common/utils/date.utils';

interface Props {
    content?: AdvertisingContent;
    control: Control<any>;
    handleSubmit: UseFormHandleSubmit<any>;
    onSubmit: (data: any) => void;
    errors: any;
    error?: string;
    loading?: boolean;
    isEdit?: boolean;
    file?: any;
    setFile: (file: any) => void;
    setValue: any;
    onArchive?: () => void;
}

const AdvertisingContentForm: FC<Props> = ({
    content,
    control,
    handleSubmit,
    onSubmit,
    errors,
    error,
    loading,
    file,
    isEdit,
    setFile,
    setValue,
    onArchive,
}) => {
    const navigate = useNavigate();
    const goBack = () => navigate(-1);

    const [hasFixedDuration, setHasFixedDuration] = useState(false);

    useEffect(() => {
        if (content && content?.file_type === 'VIDEO') {
            setHasFixedDuration(true);
        }
    }, [content]);

    // FILE INPUT
    const handleFileInputChange = (event: any) => {
        const file = event.target.files[0];

        setFile(file);

        const isVideo = file?.type?.startsWith('video/');
        if (isVideo) {
            const videoElement = document.createElement('video');
            const fileURL = URL.createObjectURL(file);

            videoElement.src = fileURL;
            videoElement.preload = 'metadata';

            // Wait for the video metadata to load
            videoElement.onloadedmetadata = () => {
                const videoDuration = Math.ceil(videoElement.duration);
                const formattedDuration = intSecondsToFormattedMinutes(videoDuration);
                setValue('duration', formattedDuration); // Duration in seconds
                setHasFixedDuration(true);
                URL.revokeObjectURL(fileURL); // Free memory
            };
        } else {
            setHasFixedDuration(false);
        }
    };

    const submitDisabled = !isEdit && !file;

    const [modalConfirmation, setModalConfirmation] = useState(false);

    const openModalConfirmation = (e?: any) => {
        e?.preventDefault();
        setModalConfirmation(true);
    };

    const closeModalConfirmation = () => setModalConfirmation(false);

    return (
        <>
            <form className="flex flex-col space-y-6 divide-y" onSubmit={handleSubmit(onSubmit)}>
                <ErrorMessage message={error} />
                <Section>
                    <FormDescriptionColumn
                        title="Conteúdos de publicidade"
                        description={[
                            'Conteúdos para organizar campanhas de publicidade',
                            'As dimenções recomendadas são 1000px x 800px (L x A)',
                            'O audio do vídeo também será reproduzido na máquina.',
                        ]}
                    />
                    <FormColumn>
                        {content?.archived_at && (
                            <p className="text-lg font-bold text-red-600">Conteúdo arquivado</p>
                        )}
                        <Input
                            control={control}
                            name="name"
                            label="Nome"
                            error={errors?.title}
                            className="sm:w-full"
                        />
                        <NumberInput
                            control={control}
                            name="duration"
                            label="Duração"
                            type="number"
                            className="sm:w-full"
                            error={errors?.address?.duration}
                            format="##:##"
                            disabled={hasFixedDuration}
                        />
                        <InputContainer name="file_url" label="Arquivo" className="!w-full">
                            <FileInput
                                name="file_url"
                                onChange={handleFileInputChange}
                                className="w-full"
                                type="file"
                                data={file}
                                accept=".mp4, .jpg, .png"
                            />
                        </InputContainer>
                        {content && !file && <ContentPreview content={content} />}
                    </FormColumn>
                </Section>
                <div className="flex justify-between items-center pt-7 text-sm">
                    {isEdit && !content?.archived_at && (
                        <AccentButton
                            type="button"
                            loading={loading}
                            className="px-4 py-2 ml-3 w-max"
                            color="red"
                            onClick={openModalConfirmation}
                        >
                            Arquivar conteúdo
                        </AccentButton>
                    )}
                    <div className="flex justify-end items-center">
                        <Link to="#" className="px-7 py-2 font-bold" onClick={goBack}>
                            Cancelar
                        </Link>
                        <AccentButton
                            type="submit"
                            disabled={submitDisabled}
                            loading={loading}
                            className="px-4 py-2 ml-3 w-max"
                        >
                            Salvar
                        </AccentButton>
                    </div>
                </div>
            </form>
            <DeleteConfirmationModal
                title="Arquivar Conteúdo"
                description="Você tem certeza que deseja arquivar esse conteúdo? Não será possivel utilizar o conteúdo em novas camapanhas. Campanhas que já utilizam o conteúdo continuarão o apresentando."
                open={modalConfirmation}
                closeModal={closeModalConfirmation}
                acceptButtonAction={onArchive}
                acceptButtonCompleteLabel="Sim, arquivar conteúdo"
                loading={loading}
            />
        </>
    );
};

interface Children {
    children?: ReactNode;
}

const Section: FC<Children> = ({ children }) => {
    return (
        <div className="pt-7 pb-7 w-full md:grid md:grid-cols-3 md:gap-6 first:pt-0 last:pb-0">
            {children}
        </div>
    );
};

const FormColumn: FC<Children> = ({ children }) => {
    return <div className="mt-5 space-y-6 w-full md:col-span-2 md:mt-0">{children}</div>;
};

export default AdvertisingContentForm;
